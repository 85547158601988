// requires additional assets to work
// <link href="https://assets.calendly.com/assets/external/widget.css" rel="stylesheet">
// <script src="https://assets.calendly.com/assets/external/widget.js" type="text/javascript"></script>
import { ready } from '#js/components/utils'

ready(function () {
  // Integrate Calendly widget via "data-calendly-url" attribute
  document.querySelectorAll('[data-calendly-url]').forEach(function (element) {
    element.addEventListener('click', (event) => {
      event.preventDefault()
      window.Calendly.initPopupWidget({
        url: element.dataset.calendlyUrl,
        prefill: {
          name: element.dataset.calendlyName ? element.dataset.calendlyName : '',
          email: element.dataset.calendlyEmail ? element.dataset.calendlyEmail : ''
        }
      })
      return false
    })

    // we do get the same event 3 times, but only need 1
    window.calendlySuccessLoading = false
    window.calendlyDateAndTimeSelected = false

    window.addEventListener('message', (event) => {
      if (!window.calendlyDateAndTimeSelected && event.data.event === 'calendly.date_and_time_selected') {
        window.dataLayer.push({
          event: 'expert_begin_checkout'
        })
        window.calendlyDateAndTimeSelected = true
      }

      if (!window.calendlySuccessLoading && event.data.event === 'calendly.event_scheduled') {
        if (element.dataset.calendlySuccessUrl) {
          window.calendlySuccessLoading = true
          const data = new FormData()
          data.append('csrfmiddlewaretoken', document.querySelector('input[name=csrfmiddlewaretoken]').value)
          fetch(element.dataset.calendlySuccessUrl, {
            method: 'POST',
            body: data,
            credentials: 'same-origin'
          }).then((response) => {
            window.calendlySuccessLoading = false
            return response.json()
          }).then(function (data) {
            window.dataLayer.push({
              event: 'expert_purchase',
              expertBookingTransactionId: data.expert_booking_id
            })
            window.location.reload()
          }).catch((error) => {
            window.calendlySuccessLoading = false
            console.error(error)
          })
        }
      }
    })
  })
})
